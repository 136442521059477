.logo-icon {
  margin-top: 105px;
  margin-bottom: 50px;
}

.btn-login {
  margin-top: 60px;
  color: '#6B8FED';
  border-color: '#6B8FED';
  font-weight: 'bold';
  width: 117px;
}

.title {
  background-color: #ffffff;
  font-size: 14px;
  -webkit-box-shadow: 0 3px 5px -3px #000;
  -moz-box-shadow: 0 3px 5px -3px #000;
  box-shadow: 0 3px 5px -3px #000;
  padding-bottom: 2px;
  padding-left: 30px;
  padding-top: 2px;
  font-weight: bold;
}

.input {
  width: 410px;
  justify-content: flex-end;
}

.item-input {
  display: flex;
  width: 560px;
}

.scrollbar {
  overflow-x: auto;
  padding-bottom: 10px;
}

.scroll-hover {
  overflow-y: hidden;
}

.scroll-hover:hover {
  overflow-y: auto;
}

/* The Modal (background) */
.modal {
  /*display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(88, 89, 98, 0.9); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  position: relative;
  margin: auto;
  padding: 0;
  /* border: 1px solid #888; */
  width: 512px;
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

/* The Close Button */
.close {
  color: white;
  float: right;
  font-size: 50px;
  font-weight: bold;
  z-index: 99;
}

/* src/ImageGallery.css */
.image-gallery {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  background: red;
}

.main-image img {
  width: 300px;
  height: auto;
  margin-right: 8px;
}

.side-images {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 150px;
}

.side-images img {
  width: 100%;
  height: auto;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px; /* khoảng cách giữa các ảnh, bạn có thể thay đổi giá trị này */
}

.image-item {
  width: 100%;
  padding-top: 100%; /* để tạo một hình vuông */
  position: relative;
}

.image-item img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* để đảm bảo ảnh không bị méo */
  border-radius: 8px;
}
/* div:hover {
  overflow-y: scroll;
} */

.template-form-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  /* font-family: Arial, sans-serif; */
}

.template-form-container h1 {
  text-align: center;
  margin-bottom: 20px;
}

.template-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input[type='text'],
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-group textarea {
  height: 100px;
}

.image-upload {
  border: 1px dashed #ccc;
  padding: 20px;
  text-align: center;
  border-radius: 5px;
  position: relative;
}

.image-upload input[type='file'] {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}

/* .image-upload img {
  max-width: 100%;
  margin-top: 10px;
} */

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* button:hover {
  background-color: #0056b3;
} */

.product-form {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

/* .form-group input,
.form-group textarea { */

.flex-row {
  display: flex;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-card {
  flex: 1;
}
