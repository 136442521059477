@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Noto Sans JP', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Noto Sans JP', sans-serif;
}

.ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
  visibility: visible !important;
}

.ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
  visibility: visible !important;
}

.ant-picker-panels > *:last-child {
  display: none;
}

.ant-picker-footer-extra > div {
  flex-wrap: wrap !important;
}

.ant-menu-item-selected {
  background-color: #323539 !important;
  color: #ffffff !important;
}

.ant-menu-item {
  background-color: #ffffff;
  margin-bottom: 15px !important;
}

.ant-modal {
  padding: 0;
}

.ant-modal-content {
  margin: 0;
  padding: 0;
}
